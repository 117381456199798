import { ConnectAccountOnboarding } from "@stripe/react-connect-js";
import { useState } from "react";
import { ConnectComponentsProvider } from "@stripe/react-connect-js"; // theme css file
import { loadConnectAndInitialize } from "@stripe/connect-js";
import { post } from "../../helper/fetch";
const publishableKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;

export default function MoneyPage() {
  const [error, setError] = useState<string | null>(null);
  const [accountLink, setAccountLink] = useState<string | null>(null);
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      try {
        const response = await post<{ client_secret: string }>('/me/stripe/account_session', {});
        if (!response.data) {
          // Handle errors on the client side here
          const { error } = response;
          console.error('An error occurred: ', error);
          try {
            const accountLink = await post<{ account_link: string }>('/me/stripe/account_link', {});
            setAccountLink(accountLink.data.account_link);
          } catch (accountLinkError) {
            console.error("Failed to get account link:", accountLinkError);
            setError("Sorry, we couldn't initiate your money settings. Please contact admins");
          }
          return undefined;
        }
        return response.data.client_secret;
      } catch (error) {
        console.error("Failed to get account session:", error);
        try {
          const accountLink = await post<{ account_link: string }>('/me/stripe/account_link', {});
          setAccountLink(accountLink.data.account_link);
        } catch (accountLinkError) {
          console.error("Failed to get account link:", accountLinkError);
          setError("Sorry, we couldn't initiate your money settings. Please contact admins");
        }
        return undefined;
      }
    }

    return loadConnectAndInitialize({
      // This is a placeholder - it should be replaced with your publishable API key.
      // Sign in to see your own test API key embedded in code samples.
      // Don't submit any personally identifiable information in requests made with this key.
      publishableKey: publishableKey,
      fetchClientSecret: fetchClientSecret,
    })
  })

  return (
    <div className="flex justify-center items-center min-h-screen p-6">
      <div className="w-full max-w-2xl">
        {error && <div className="text-red-500 mb-4">{error}</div>}
        {accountLink && <div className="bg-white rounded-lg shadow-sm p-6">
          <p className="text-sm text-gray-500 mb-3">Please follow the link below to connect your Stripe account:</p>
          <a href={accountLink} target="_blank" rel="noreferrer" className="text-blue-600 hover:text-blue-800 break-all">
            {accountLink}
          </a>
        </div>}
        {!error && !accountLink && <div className="bg-white rounded-lg shadow-sm p-6">
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectAccountOnboarding
              onExit={() => {
                console.log("The account has exited onboarding");
              }}
              // Optional: make sure to follow our policy instructions above
              // fullTermsOfServiceUrl="{{URL}}"
              // recipientTermsOfServiceUrl="{{URL}}"
              // privacyPolicyUrl="{{URL}}"
              skipTermsOfServiceCollection={false}
              collectionOptions={{
                fields: 'eventually_due',
                futureRequirements: 'include',
              }}
            />
          </ConnectComponentsProvider>
        </div>}
      </div>
    </div>
  );
}