import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LocationMarkerIcon, ClockIcon, CurrencyDollarIcon, LightningBoltIcon } from "@heroicons/react/outline";
import { get } from "../../helper/fetch";
import Logoimage from "../../image/logo-hs.png";
import { BookingDetails, RoomPriority } from "./BookingOffers";
import { Dialog } from "@headlessui/react";

type OfferParams = {
  offerId: string;
};

type DialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  priorities: RoomPriority[];
};

function StopCleaningDialog({ isOpen, onClose, onConfirm, priorities }: DialogProps) {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-md bg-white rounded-lg p-6">
          <Dialog.Title className="text-xl font-semibold mb-4">
            Stop Cleaning Confirmation
          </Dialog.Title>

          <div className="mb-6">
            <p className="text-gray-700 mb-4">Have you finished all of these priorities?</p>

            <div className="bg-gray-50 p-4 rounded-lg">
              <ul className="list-disc list-inside space-y-2">
                {[...priorities]
                  .sort((a, b) => a.order - b.order)
                  .map((room) => (
                    <li key={room.id} className="text-gray-700">
                      {room.name} (Priority: {room.order})
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-opacity-50 transition-colors"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition-colors"
              onClick={onConfirm}
            >
              Stop Cleaning
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default function BookingOffer() {
  const { offerId } = useParams<OfferParams>();
  const [bookingDetails, setBookingDetails] = useState<BookingDetails | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isStopDialogOpen, setIsStopDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchOfferDetails = async () => {
      try {
        setIsLoading(true);
        const response = await get<BookingDetails>(`/offers/${offerId}`);
        if (response.error) {
          throw new Error(response.error);
        }
        setBookingDetails(response.data);
        setError(null);
      } catch (err) {
        setError(err.message || "Failed to fetch offer details. Please try again later.");
        // console.error("Error fetching offer details:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOfferDetails();
  }, [offerId]);

  const handleAcceptOffer = async () => {
    try {
      const response = await get(`/offers/${offerId}/accept`);

      if (response.error) {
        throw new Error(response.error);
      }
      console.log(response);
      window.location.href = "/bookings";
    } catch (err) {
      setError(err.message || "Failed to accept offer. Please try again later.");
    }
  }

  const handleRejectOffer = async () => {
    try {
      const response = await get(`/offers/${offerId}/reject`);
      if (response.error) {
        throw new Error(response.error);
      }
      console.log(response);
      window.location.href = "/bookings";
    } catch (err) {
      setError(err.message || "Failed to reject offer. Please try again later.");
    }
  }

  const handleStopCleaning = async () => {
    try {
      const response = await get(`/offers/${offerId}/stop`);
      if (response.error) {
        throw new Error(response.error);
      }
      window.location.href = "/bookings";
    } catch (err) {
      setError(err.message || "Failed to stop cleaning. Please try again later.");
    }
  };

  if (isLoading) {
    return <div className="min-h-screen bg-gray-100 flex items-center justify-center">Loading...</div>;
  }

  if (error || !bookingDetails) {
    return <div className="min-h-screen bg-gray-100 flex items-center justify-center text-red-600">{error || "Offer not found"}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md space-y-8">
        <div className="text-center">
          <div className="bg-white rounded-full p-3 inline-block shadow-md">
            <img src={Logoimage} alt="Homespritz Logo" className="h-8 w-auto" />
          </div>
          <h1 className="mt-4 text-2xl font-bold">Hi {bookingDetails.cleanerName}</h1>
          <p className="text-gray-600">You have a new booking offer!</p>
          <p className="text-gray-600">
            {bookingDetails.count === 1
              ? "Only you have received this offer"
              : `${bookingDetails.count} people have also received this offer`}
          </p>
        </div>

        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <div className="px-6 py-4">
            <h2 className="text-xl font-semibold mb-4">Booking Details</h2>
            <div className="space-y-4">
              {bookingDetails.isDirect && (
                <div className="flex items-center space-x-2">
                  <LightningBoltIcon className="h-5 w-5 text-yellow-500 animate-pulse" />
                  <span className="text-gray-700 font-semibold">Direct booking request from Client.</span>
                </div>
              )}
              {bookingDetails.cleaningType && (
                <div className="flex items-center space-x-2">
                  <p className=" text-gray-700"> Type: {bookingDetails.cleaningType}</p>
                </div>
              )}
              {bookingDetails.recurrence && (
                <div className="flex items-center space-x-2">
                  <p className="text-gray-700"> Recurrence: {bookingDetails.recurrence}</p>
                </div>
              )}
              <div className="flex items-start space-x-2">
                <LocationMarkerIcon className="h-5 w-5 text-gray-500 shrink-0 mt-0.5" />
                <span>{bookingDetails.address}</span>
              </div>
              {bookingDetails.other_info && (
                <div className="flex items-center space-x-2">
                  <p className="text-gray-700"> Other Info: {bookingDetails.other_info}</p>
                </div>
              )}
              <div className="flex items-center space-x-2">
                <CurrencyDollarIcon className="h-5 w-5 text-gray-500" />
                <span>${bookingDetails.price}</span>
              </div>
              <div className="flex items-center space-x-2">
                <ClockIcon className="h-5 w-5 text-gray-500" />
                <span>{bookingDetails.hours}</span>
              </div>
              <div>
                <h3 className="font-semibold mb-2">Extras:</h3>
                <ul className="list-disc list-inside pl-2 space-y-1">
                  {bookingDetails.extras.map((extra, index) => (
                    <li key={index}>{extra.name}</li>
                  ))}
                </ul>
              </div>
              {bookingDetails.cleaning_priorities && bookingDetails.cleaning_priorities.length > 0 && (
                <div>
                  <h3 className="font-semibold mb-2">Cleaning Priorities:</h3>
                  <p className="text-gray-700 mb-2">Please prioritize the following order of cleaning:</p>
                  <ul className="list-disc list-inside pl-2 space-y-1">
                    {[...bookingDetails.cleaning_priorities]
                      .sort((a, b) => a.order - b.order)
                      .map((room) => (
                        <li key={room.id} className="text-gray-700">
                          {room.name} (Priority: {room.order})
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="px-6 py-4 bg-gray-50 flex justify-between">
            <button
              className="w-[45%] px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-opacity-50 transition-colors"
              onClick={handleRejectOffer}
            >
              Reject
            </button>
            <button
              className="w-[45%] px-4 py-2 bg-lime-600 rounded-md text-white hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-opacity-50 transition-colors"
              onClick={handleAcceptOffer}
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}