import React, { useContext, lazy, Suspense, useEffect, useState } from "react";
import { Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import {
  LOGIN,
  RESETPASSWORD,
  BOOKINGS,
  LISTING,
  BOOKING,
  NOTIFICATIONS,
  SCHEDULE,
  MONEY,
  BOOKING_OFFER,
  EARNINGS,
} from "./settings/constants";
import AuthProvider, { AuthContext } from "./context/auth";
import { InLineLoader } from "./components/InlineLoader/InlineLoader";
import Bookings2 from "./containers/Bookings/Bookings2";
import BookingOffer from "./containers/BookingOffer/BookingOffer";
import ResetPassword from "./containers/ResetPassword/ResetPassword";
import Booking from "./containers/Booking/Booking";
import CleanerProfile from "./containers/CleanerProfile/CleanerProfile";
import TimeOff from "./containers/TimeOff/TimeOff";
import MoneyPage from "./containers/MoneyPage/MoneyPage";
import BookingOfferList from "./containers/BookingOffer/BookingOffers";
import AnnouncementManager from "./containers/Announcements/AnnouncementManager";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { get } from "./helper/fetch";
import Earnings from "./containers/Earnings/Earnings";
const AdminLayout = lazy(() => import("./containers/Layout/Layout"));

const Login = lazy(() => import("./containers/Login/Login"));
const NotFound = lazy(() => import("./containers/NotFound/NotFound"));


/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated
          ? (children)
          : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

type StripeRequirementsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onRedirect: () => void;
};

/**
 * Modal component to display stripe requirements warning using HeadlessUI Dialog
 */
const StripeRequirementsModal = ({ isOpen, onClose, onRedirect }: StripeRequirementsModalProps) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={onClose} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="bg-white p-6 rounded-lg max-w-md">
              <Dialog.Title className="text-lg font-medium text-gray-900 mb-4">
                Bank Account Verification Required
              </Dialog.Title>
              <Dialog.Description className="text-sm text-gray-500 mb-4">
                You have outstanding bank account verification requirements. These need to be completed to avoid payment issues.
              </Dialog.Description>

              <div className="flex justify-end gap-3">
                <button
                  onClick={onClose}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Close
                </button>
                <button
                  onClick={onRedirect}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                >
                  Verify Now
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

const Routes = () => {
  const history = useHistory();
  const location = useLocation();
  const [showStripeModal, setShowStripeModal] = useState(false);

  /**
   * Checks if the user has any outstanding Stripe requirements and if enough time has passed since last shown
   * Skips showing the modal if user is already on the money page or if shown recently
   * @returns {Promise<void>}
   */
  const checkStripeRequirements = async () => {
    try {
      // Skip check if already on money page
      if (location.pathname === "/money") {
        return;
      }

      console.log("checking stripe requirements");

      // Check if we should show the modal based on time elapsed
      const lastShownTime = localStorage.getItem("stripeModalLastShown");
      const currentTime = Date.now();
      const tenMinutesInMs = 10 * 60 * 1000;

      if (lastShownTime && currentTime - Number(lastShownTime) < tenMinutesInMs) {
        return;
      }

      const response = await get("/me/stripe/requirements");
      if (response.data === true) {
        setShowStripeModal(true);
        localStorage.setItem("stripeModalLastShown", currentTime.toString());
      }
    } catch (error) {
      // Silently handle the error as it's a background check
      console.error("Failed to check stripe requirements:", error);
    }
  };

  // Run check on mount and whenever location changes
  useEffect(() => {
    checkStripeRequirements();
  }, [location.pathname]);

  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          {/* New public route for booking offer */}
          <PrivateRoute path={`/o/:offerId`}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <BookingOffer />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          {/* Existing routes */}
          <PrivateRoute exact={true} path={"/"}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Bookings2 />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={BOOKING}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Booking />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={BOOKING_OFFER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <BookingOfferList />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>



          <PrivateRoute exact path={BOOKINGS} >
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Bookings2 />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={LISTING}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CleanerProfile />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SCHEDULE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <TimeOff />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={NOTIFICATIONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <AnnouncementManager />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MONEY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <MoneyPage />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route path={RESETPASSWORD}>
            <ResetPassword />
          </Route>
          <Route path={EARNINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Earnings />
              </Suspense>
            </AdminLayout>
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
      <StripeRequirementsModal
        isOpen={showStripeModal}
        onClose={() => setShowStripeModal(false)}
        onRedirect={() => {
          setShowStripeModal(false);
          history.push("/money");
        }}
      />
    </AuthProvider>
  );
};

export default Routes;
