// **************** ROUTE CONSTANT START **************************
// General Page Section
export const LOGIN = '/login';
export const RESETPASSWORD = '/reset-password';
export const SCHEDULE = '/schedule';
export const BOOKINGS = '/bookings';
export const BOOKING = '/bookings/:id';
export const SETTINGS = '/settings';
export const STUFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
export const LISTING = '/listing';
export const NOTIFICATIONS = '/notifications';
export const MONEY = '/money';
export const EARNINGS = '/earnings';
export const BOOKING_OFFER = '/booking-offers';
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$';
