import React, { useEffect, useState } from "react";
import { get } from "../../helper/fetch";

/**
 * Type definitions for earnings data
 */
type BookingDetail = {
  id: number;
  cleaner_total: number;
  booking_date: string;
  address: string;
  status: string;
};

type YearlyEarnings = {
  total: number;
  earnings: BookingDetail[];
};

type DateRange = {
  from: string;
  to: string;
};

/**
 * Converts booking details to CSV format and triggers download
 */
const exportToCSV = (bookings: BookingDetail[], dateRange: DateRange, total: number) => {
  // Define CSV metadata and headers
  const metadata = [
    ["Earnings Report"],
    [`Date Range: "${new Date(dateRange.from).toLocaleDateString()} - ${new Date(dateRange.to).toLocaleDateString()}"`],
    [`Total Earnings: $${(total)}`],
    [`Total Bookings: ${bookings.length}`],
    [], // Empty row for spacing
    ["Booking Details:"],
    ["Booking ID", "Date", "Amount", "Status", "Address"] // Column headers
  ];

  // Convert bookings to CSV rows
  const rows = bookings.map(booking => [
    booking.id,
    `"${booking.booking_date}"`,
    `"$${booking.cleaner_total.toLocaleString()}"`,
    `"${booking.status}"`,
    `"${booking.address}"`
  ]);

  // Combine metadata, headers and rows
  const csvContent = [
    ...metadata,
    ...rows
  ].map(row => row.join(",")).join("\n");

  // Create download link
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);

  // Set download attributes with date range in filename
  const filename = `earnings_${dateRange.from}_to_${dateRange.to}.csv`;
  link.setAttribute("href", url);
  link.setAttribute("download", filename);

  // Trigger download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Earnings component that displays the total earnings and booking details for selected date range
 */
const Earnings: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [earningsData, setEarningsData] = useState<YearlyEarnings>({
    total: 0,
    earnings: []
  });

  // Initialize date range with current year
  const [dateRange, setDateRange] = useState<DateRange>(() => {
    const currentYear = new Date().getFullYear();
    return {
      from: `2024-01-01`,
      to: `${currentYear}-12-31`
    };
  });

  /**
   * Fetches earnings data for the specified date range
   */
  const fetchEarningsData = async () => {
    try {
      setLoading(true);
      const params = new URLSearchParams({
        from: dateRange.from,
        to: dateRange.to
      });
      const response = await get(`/me/earnings?${params.toString()}`);
      setEarningsData(response.data as YearlyEarnings);
    } catch (err) {
      if (err.status === 400 && err.data?.error) {
        setError(err.data.error);
      } else {
        setError("Failed to load earnings data. Please try again later.");
      }
      console.error("Error fetching earnings:", err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle date range change and trigger data fetch
   */
  const handleDateChange = (field: keyof DateRange) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateRange(prev => ({
      ...prev,
      [field]: e.target.value
    }));
  };

  useEffect(() => {
    fetchEarningsData();
  }, [dateRange.from, dateRange.to]);

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Earnings Overview</h1>
        <div className="flex gap-4 items-center">
          <div className="flex items-center gap-2">
            <label htmlFor="from-date" className="text-sm font-medium text-gray-600">
              From:
            </label>
            <input
              id="from-date"
              type="date"
              value={dateRange.from}
              onChange={handleDateChange("from")}
              max={dateRange.to}
              className="border rounded px-2 py-1 text-sm"
              disabled={loading}
            />
          </div>
          <div className="flex items-center gap-2">
            <label htmlFor="to-date" className="text-sm font-medium text-gray-600">
              To:
            </label>
            <input
              id="to-date"
              type="date"
              value={dateRange.to}
              onChange={handleDateChange("to")}
              min={dateRange.from}
              className="border rounded px-2 py-1 text-sm"
              disabled={loading}
            />
          </div>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded mb-6">
          {error}
        </div>
      )}

      {loading ? (
        <div className="space-y-4">
          <div className="bg-white rounded-lg shadow p-6 mb-6">
            <div className="animate-pulse">
              <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
              <div className="h-8 bg-gray-200 rounded w-1/3"></div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <div className="animate-pulse">
              <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
              <div className="space-y-3">
                <div className="h-10 bg-gray-200 rounded"></div>
                <div className="h-10 bg-gray-200 rounded"></div>
                <div className="h-10 bg-gray-200 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="bg-white rounded-lg shadow p-6 mb-6">
            <h2 className="text-xl font-semibold mb-4">Total Earnings</h2>
            <p className="text-sm text-gray-600 mb-4">
              {`${dateRange.from} - ${dateRange.to}`}
            </p>
            <p className="text-sm text-gray-600 mb-4">
              Please note that some bookings may have been paid out manually and not reflected in the total earnings.
              If you have any questions, please contact support.
            </p>
            <div className="text-3xl font-bold text-green-600">
              ${(earningsData.total).toLocaleString()}
            </div>
            <div className="text-lg text-gray-600 mt-2">
              Total Bookings: {earningsData.earnings.length}
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Booking Details</h2>
              {earningsData.earnings.length > 0 && (
                <button
                  onClick={() => exportToCSV(
                    earningsData.earnings,
                    dateRange,
                    earningsData.total
                  )}
                  className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition-colors flex items-center gap-2"
                >
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>
                  Export to CSV
                </button>
              )}
            </div>
            {earningsData.earnings.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full table-auto">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="px-4 py-2 text-left text-gray-500">Booking ID</th>
                      <th className="px-4 py-2 text-left text-gray-500">Date</th>
                      <th className="px-4 py-2 text-left text-gray-500">Amount</th>
                      <th className="px-4 py-2 text-left text-gray-500">Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {earningsData.earnings.map((earning) => (
                      <tr key={earning.id} className="border-t">
                        <td className="px-4 py-2">{earning.id}</td>
                        <td className="px-4 py-2">
                          {earning.booking_date}
                        </td>
                        <td className="px-4 py-2">
                          ${earning.cleaner_total.toLocaleString()}
                        </td>
                        <td className="px-4 py-2">
                          {earning.address}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-gray-500 text-center py-4">
                No bookings found for the selected date range
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Earnings; 